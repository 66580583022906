define('ember-feature-flags/helpers/feature-flag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    features: Ember.inject.service(),

    compute([flag]) {
      if (this._observedFlag) {
        this.get('features').removeObserver(this._observedFlag, this, 'recompute');
      }

      this.set('_observedFlag', Ember.String.camelize(flag));
      this.get('features').addObserver(this._observedFlag, this, 'recompute');

      return this.get('features').isEnabled(flag);
    },

    _observedFlag: null,

    willDestroy() {
      this._super(...arguments);

      if (this._observedFlag) {
        this.get('features').removeObserver(this._observedFlag, this, 'recompute');
      }
    }
  });
});