define('ember-inputmask/components/ssn-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _inputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _inputMask.default.extend({
    mask: '999-99-9999',

    oldComponent: '{{ssn-input}}',
    newComponent: '{{one-way-ssn-mask}}',

    updateMask() {
      this.set('mask', '999-99-9999');
      this._super();
    },

    _maskShouldChange: Ember.observer('mask', function () {
      Ember.run.once(this, 'updateMask');
    })
  });
});